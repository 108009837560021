import * as Cookie from 'utils/cookie';
import * as Help from 'utils/helpers';
// import openSocket from 'socket.io-client';
import urls from './config';

const env = process.env.REACT_APP_ENV;
const api = urls[env].route;
// const socket_api = urls[env].socket;
// const socket = openSocket(socket_api);

// PROMOS MODULE

export const getAllPromos = (
  page,
  limit,
  id,
  title,
  date_from,
  date_to,
  is_enable,
  is_online,
  is_active,
  order_number,
  sorted
) => {
  const config = {};
  config.method = 'GET';
  config.route = `/promos?page=${page}&limit=${limit}&id=${id}&title=${title}&date_from=${date_from}&date_to=${date_to}&is_enable=${is_enable}&is_online=${is_online}&is_active=${is_active}&order_number=${order_number}&sort=${sorted}`;
  return queryService(config);
};

export const getOnePromo = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/promo/${id}`;
  return queryService(config);
};

export const getPromoProducts = (
  id,
  page,
  limit,
  prod_id,
  name,
  sku,
  price,
  special_price,
  sorted
) => {
  const config = {};
  config.method = 'GET';
  config.route = `/promo/${id}/products?page=${page}&limit=${limit}&id=${prod_id}&name=${name}&sku=${sku}&price=${price}&special_price=${special_price}&sort=${sorted}`;
  return queryService(config);
};

export const deletePromoProducts = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/promo_products/${id}`;
  return queryService(config);
};

export const getPromoSetting = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/promo/setting`;
  return queryService(config);
};

export const postPromos = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/promo`;
  config.body = body;
  return queryService(config);
};

export const putPromos = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/promo/${id}`;
  config.body = body;
  return queryService(config);
};

export const putPromosSetting = (body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/promo/setting`;
  config.body = body;
  return queryService(config);
};

export const unlinkBulkUpload = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/promo/products/${id}`;
  return queryService(config);
};
export const deletePromo = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/promo/${id}`;
  return queryService(config);
};

export const bulkUpload = (body, id) => {
  let bulktoken = Cookie.getToken();
  const fd = new FormData();
  fd.append('file', body);
  return fetch(
    `${api}/promo/products/${bulktoken ? bulktoken.token : ''}?promo_id=${id}`,
    {
      method: 'POST',
      headers: {},
      body: fd,
    }
  ).then((res) => {
    return res.json();
  });
};

// PAWNHERO COURIER

let token = Cookie.getToken();
const headers = {
  Accept: 'application/json',
  Authorization: token ? `Bearer ${token.token}` : '',
  'Content-Type': 'application/json',
};
export const abortController = new AbortController();

// BULK UPDATE
export const bulkUpdate = (body) => {
  let bulktoken = Cookie.getToken();
  const fd = new FormData();
  fd.append('file', body);
  return fetch(`${api}/multi/products/${bulktoken ? bulktoken.token : ''}`, {
    method: 'PUT',
    headers: {},
    body: fd,
  }).then((res) => {
    return res.json();
  });
};

// STOCK PRODUCTS
export const postStock = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/stock`;
  config.body = body;
  return queryService(config);
};

// WMS ATTRIBUTE CHECKING
export const attributeChecking = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/wms/receiving/attribute`;
  config.body = body;
  return queryService(config);
};

// WMS WITHDRAWAL
export const getAllWithdrawal = (
  pageSize,
  page,
  sort,
  search,
  slip_number,
  type,
  created,
  requested_by,
  created_by,
  status
) => {
  const config = {};
  config.method = 'GET';
  config.route = `/wms/withdraw?limit=${pageSize}&page=${page}&sort=${sort}&search=${search}&slip_number=${slip_number}&type=${type}&requested_by=${requested_by}&created_by=${created_by}&status=${status}&created=${created}`;
  return queryService(config);
};

export const getOneWithdrawal = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/wms/withdraw/${id}`;
  return queryService(config);
};

export const addWithdrawalItem = (sku) => {
  const config = {};
  config.method = 'GET';
  config.route = `/wms/withdraw/product/${sku}`;
  return queryService(config);
};
export const addWithdrawalSlip = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/wms/withdraw`;
  config.body = body;
  return queryService(config);
};
export const putWithdrawalSlip = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/wms/withdraw/${id}`;
  config.body = body;
  return queryService(config);
};

// WMS RECEIVING
export const getAllReceiving = (
  sku,
  pageSize,
  page,
  sort,
  rc_number,
  created,
  received_by,
  requested_by,
  created_by,
  status
) => {
  const config = {};
  config.method = 'GET';
  config.route = `/wms/receiving?search=${sku}&limit=${pageSize}&page=${page}&sort=${sort}&rc_number=${rc_number}&received_by=${received_by}&requested_by=${requested_by}&created_by=${created_by}&status=${status}&created=${created}`;
  return queryService(config);
};

export const getOneReceiving = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/wms/receiving/${id}`;
  return queryService(config);
};

export const postReceivingSlip = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/wms/receiving/slip`;
  config.body = body;
  return queryService(config);
};

export const putReceivingSlip = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/wms/receiving/slip/${id}`;
  config.body = body;
  return queryService(config);
};

//WMS REPORT GENERATION

export const getAllInventory = (
  sort_by = 'entity_id',
  sort = 'ASC',
  page = 1,
  limit = 10,
  category = '',
  brand = '',
  sku = '',
  location = '',
  order_status = '',
  item_status = '',
  loan_amount = '',
  price = '',
  first_auction_date = [],
  age = '',
  aging = ''
) => {
  const config = {};
  config.method = 'GET';
  let route = `/reports/inventory?sort_by=${sort_by}&sort=${sort}&page=${page}&limit=${limit}&category=${category}&brand=${brand}`;
  route = `${route}&sku=${sku}&location=${location}&order_status=${order_status}&item_status=${item_status}`;
  route = `${route}&loan_amount=${loan_amount}&price=${price}&first_auction_date=${first_auction_date}`;
  route = `${route}&age=${age}&aging=${aging}`;
  config.route = route;
  return queryService(config);
};

export const getAllAging = (field = 'price') => {
  const config = {};
  config.method = 'GET';
  config.route = `/reports/aging?field=${field}`;
  return queryService(config);
};

// PAWNHERO COURIER API
export const createDelivery = (body, id) => {
  const config = {};
  config.method = 'POST';
  config.route = `/courier/express/delivery`;
  config.body = body;
  return queryService(config);
};
//

//USER RESSOURCES
export const getUserRessources = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/resources/${id}`;
  return queryService(config);
};

// START NOTIFICATIONS

export const getAllSNS = (page, pageSize, filterBy, filter) => {
  const config = {};
  config.method = 'GET';
  config.route = `/announcement?offset=${page}&limit=${pageSize}&sort_by=${filterBy}&sort=${filter}`;
  return queryService(config);
};
export const getSNS = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/announcement/${id}`;
  return queryService(config);
};
export const postSNS = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/announcement`;
  config.body = body;
  return queryService(config);
};
export const putSNS = (body, id) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/announcement/${id}`;
  config.body = body;
  return queryService(config);
};
export const sendSNS = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/send_notif`;
  config.body = body;
  return queryService(config);
};
export const blastSNS = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/send_notifs_all`;
  config.body = body;
  return queryService(config);
};
export const deleteSNS = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/announcement/${id}`;
  return queryService(config);
};

// END NOTIFICATIONS

// SOCKET // SALES DASHBOARD

// export const connection = (cb) => {
//   let token = Cookie.getToken()
//   const headers = {
//     'Accept': 'application/json',
//     'Authorization': token ? `Bearer ${token.token}` : '',
//     'Content-Type': 'application/json'
//   }
//   socket.on('connection')
//   fetch(`${socket_api}/api/v1/sales/dashboard`, {
//     method: 'GET',
//     headers
//   })
//   .then(response => response.json())
//   .catch(error => console.log(error))
//   socket.on('sales', data => {
//       cb(null, data)
//   })
// }

export const putSalesDashboard = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/sales/dashboard/${id}`;
  config.body = body;
  return queryService(config);
};
// END SOCKET

// SEO

export const getAllStaticPages = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/static_pages`;
  return queryService(config);
};
export const deleteSEO = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/static_pages/${id}`;
  return queryService(config);
};
export const putSEO = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/static_pages/${id}`;
  config.body = body;
  return queryService(config);
};
export const postSEO = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/static_page`;
  config.body = body;
  return queryService(config);
};

// DASHBOARD
export const getDashboard = (sort) => {
  const config = {};
  config.method = 'GET';
  config.route = `/admin/dashboard?sort=${sort}`;
  return queryService(config);
};

// RESET
export const reset = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = '/admin/reset-password';
  config.body = body;
  return queryService(config);
};
export const checkToken = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = '/admin/check-reset-token';
  config.body = body;
  return queryService(config);
};
// LOGIN
export const login = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = '/admin/login';
  config.body = body;
  return queryService(config);
};

export const logout = () => {
  const config = {};
  config.method = 'POST';
  config.route = '/admin/signout';
  return queryService(config);
};

export const forgotPassword = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = '/admin/forgot-password';
  config.body = body;
  return queryService(config);
};

export const getPromotionalSMS = (id, page = '', limit = '') => {
  const dataPage = page ? `page=${page}` : '';
  const dataPageLimit = limit ? `&limit=${limit}` : '';
  const getOne = id ? `sms_id=${id}` : '';

  const config = {};
  config.method = 'GET';
  config.route = `/sms?${id ? getOne : `${dataPage}${dataPageLimit}`}`;
  return queryService(config);
};

export const createPromotionalSMS = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/sms`;
  config.body = body;
  return queryService(config);
};
export const updatePromotionalSMS = (body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/sms`;
  config.body = body;
  return queryService(config);
};
export const sendPromotionalSMS = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/sms_customer_group`;
  config.body = body;
  return queryService(config);
};

export const deletePromotionalSMS = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/sms`;
  config.body = { sms_id: id };
  return queryService(config);
};

/////////////////////////////////
///////// CMS - Block ///////////
/////////////////////////////////
export const allBlock = (
  page = '',
  limit = '',
  sort_by = '',
  sort = '',
  search = '',
  type = '',
  location = '',
  is_enabled = ''
) => {
  const config = {};
  config.method = 'GET';
  config.route = `/block?offset=${page}&limit=${limit}&sort_by=${sort_by}&sort=${sort}&search=${search}&type=${type}&location=${location}&is_enabled=${is_enabled}`;
  return queryService(config);
};

export const viewBlock = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/block/${id}`;
  return queryService(config);
};

export const deleteBlockColumn = (id, body) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/block/${id}`;
  return queryService(config);
};

export const createBlockColumn = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/block`;
  config.body = body;
  return queryService(config);
};

export const updateBlockColumn = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/block/${id}`;
  config.body = body;
  return queryService(config);
};

/////////////////////////////////
///////// CMS - Pages ///////////
/////////////////////////////////
export const createPageContent = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/layout`;
  config.body = body;
  return queryService(config);
};

export const updatePageContent = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/layout`;
  config.body = body;
  return queryService(config);
};

/////////////////////////////////
// Cart Price Rule - Promotion //
/////////////////////////////////
export const allPromotion = (page = '', limit = '', filter) => {
  const formatted_filter = filter
    .map((e) => {
      return `${e.key}=${e.value ? e.value : ''}`;
    })
    .join('&');

  let token = Cookie.getToken();
  const headers = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token.token}` : '',
    'Content-Type': 'application/json',
  };

  return fetch(
    `${api}/cart_price_rule?page=${page}&limit=${limit}&${formatted_filter}`,
    {
      method: 'GET',
      headers,
    }
  ).then((res) => res.json());
};
export const viewPromotion = (id) => {
  let token = Cookie.getToken();
  const headers = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token.token}` : '',
    'Content-Type': 'application/json',
  };

  return fetch(`${api}/cart_price_rule/${id}`, {
    method: 'GET',
    headers,
  }).then((res) => res.json());
};
export const createPromotion = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/cart_price_rule`;
  config.body = body;
  return queryService(config);
};
export const attributesPromotion = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/cart_price_rule_attributes`;
  return queryService(config);
};
export const getAttibuteValues = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/attributes/${id}/values`;
  return queryService(config);
};
export const getAttibuteCondition = (backend_type) => {
  const config = {};
  config.method = 'GET';
  config.route = `/cart_price_rule_options/${backend_type}`;
  return queryService(config);
};
export const updateStatusPromotion = (body, id) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/cart_price_rule/${id}`;
  config.body = body;
  return queryService(config);
};
export const attributePromotion = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/attributes/options/${id}`;
  return queryService(config);
};
export const deletePromotion = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/cart_price_rule/${id}`;
  return queryService(config);
};

//Product Attribute
export const allProductAttribute = (
  offset = '',
  limit = '',
  sorting,
  code = '',
  label = '',
  is_required,
  is_user_defined,
  is_visible,
  is_searchable,
  is_filterable,
  is_comparable
) => {
  let token = Cookie.getToken();
  const headers = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token.token}` : '',
    'Content-Type': 'application/json',
  };

  const attribute_sort = sorting ? `&order=${sorting}` : '';
  const filter_is_required = is_required ? `&is_required=${is_required}` : '';
  const filter_is_user_defined = is_user_defined
    ? `&is_user_defined=${is_user_defined}`
    : '';
  const filter_is_visible = is_visible ? `&is_visible=${is_visible}` : '';
  const filter_is_searchable = is_searchable
    ? `&is_searchable=${is_searchable}`
    : '';
  const filter_is_filterable = is_filterable
    ? `&is_filterable=${is_filterable}`
    : '';
  const filter_is_comparable = is_comparable
    ? `&is_comparable=${is_comparable}`
    : '';

  return fetch(
    `${api}/attributes?offset=${offset}&limit=${limit}${attribute_sort}&attribute_code=${code}&attribute_label=${label}${filter_is_required}${filter_is_user_defined}${filter_is_visible}${filter_is_searchable}${filter_is_filterable}${filter_is_comparable}`,
    {
      method: 'GET',
      headers,
    }
  ).then((res) => res.json());
};

export const getProductAttribute = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/attributes/${id}`;
  return queryService(config);
};

export const deleteProductAttribute = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/attributes/${id}`;
  return queryService(config);
};

export const createProductAttribute = (body) => {
  let token = Cookie.getToken();
  const headers = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token.token}` : '',
    'Content-Type': 'application/json',
  };
  return fetch(`${api}/attributes`, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  }).then((res) => res.json());
};

export const updateProductAttribute = (id, body) => {
  let token = Cookie.getToken();
  const headers = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token.token}` : '',
    'Content-Type': 'application/json',
  };
  return fetch(`${api}/attributes/${id}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(body),
  }).then((res) => res.json());
};

///////////////////////////////////////////////////////////////
////////////////////////////Banner//////.//////////////////////
///////////////////////////////////////////////////////////////
export const allBanner = (
  offset = '',
  limit = '',
  sorting = '',
  redirect_to = '',
  is_enabled = '',
  sort = 'sorting ASC',
  entity_id = '',
  redirect_link = '',
  date_from = '',
  date_to = '',
  is_fixed = '',
  is_mobile = ''
) => {
  const config = {};
  config.method = 'GET';
  config.route = `/list-banners?sorting=${sorting}&redirect_to=${redirect_to}&is_enabled=${is_enabled}&limit=${limit}&page=${offset}&sort=${sort}&id=${entity_id}&value=${redirect_link}&start_date=${date_from}&end_date=${date_to}&is_fixed=${is_fixed}&is_mobile=${is_mobile}`;
  return queryService(config);
};

export const viewBanner = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/web-banners/${id}`;
  return queryService(config);
};

export const deleteBanner = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/web-banners/${id}`;
  return queryService(config);
};

export const createBanner = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/web-banners`;
  config.body = body;
  return queryService(config);
};

export const updateBanner = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/web-banners/${id}`;
  config.body = body;
  return queryService(config);
};

///////////////////////////////////////////////////////////////
////////////////////////////Brands//////.//////////////////////
///////////////////////////////////////////////////////////////
export const allBrands = (
  keyword,
  filter1,
  offset = '',
  limit = '',
  filterByName,
  sName,
  sFeatured,
  sSort
) => {
  let query = keyword ? `&search=${keyword}` : '';
  let filter_by = filterByName ? `&filter_by=${filterByName}` : '';
  let filter = filter1 ? `&filter=${filter1}` : '';
  let searchFeatured = sFeatured ? `&search_is_featured=${sFeatured}` : '';
  let searchSort = sSort ? `&search_sort=${sSort}` : '';
  let searchName = sName ? `&search_name=${sName}` : '';
  const config = {};
  config.method = 'GET';
  config.route = `/brands?offset=${offset}&limit=${limit}${query}${filter_by}${filter}${searchName}${searchFeatured}${searchSort}`;
  return queryService(config);
};
export const viewBrand = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/brands/${id}`;
  return queryService(config);
};

export const deleteBrand = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/brand/${id}`;
  return queryService(config);
};

export const createBrand = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/brand`;
  config.body = body;
  return queryService(config);
};

export const updateBrand = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/brand/${id}`;
  config.body = body;
  return queryService(config);
};

export const getBrandBanner = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/banner`;
  return queryService(config);
};

export const uploadBrandBanner = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/banner/${id}`;
  config.body = body;
  return queryService(config);
};

export const addBrandBanner = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/banner`;
  config.body = body;
  return queryService(config);
};

export const deleteBrandBanner = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/banner/${id}`;
  return queryService(config);
};

// resend Notification(Email) on Sales Module (Invoice, Shipment, Memo)
export const resendEmail = (id, sales_module, credit_memo_id = '') => {
  const memo_id = credit_memo_id ? `/${credit_memo_id}` : '';
  const config = {};
  config.method = 'POST';
  config.route = `/email/${sales_module}/${id}${memo_id}`;
  return queryService(config);
};
///////////////////////////////////////////////////////////////
/////////////////////Sales - Invoice///////////////////////////
///////////////////////////////////////////////////////////////
export const retrievedInvoice = (
  page = '1',
  limit = '1',
  invoice = '',
  invoice_date_from = '',
  invoice_date_to = '',
  bill_name = '',
  order_date_from = '',
  order_date_to = '',
  order_num = '',
  priceMin = 0,
  priceMax = Number.MAX_SAFE_INTEGER,
  status = '',
  columnName = ''
) => {
  const config = {};
  const route = `/invoice?page=${page}&limit=${limit}&invoice=${invoice}&bill_to_name=${bill_name}&order_number=${order_num}&amount_from=${priceMin}&amount_to=${priceMax}`;
  config.method = 'GET';
  config.route = `${route}&invoice_date_from=${invoice_date_from}&invoice_date_to=${invoice_date_to}&order_date_from=${order_date_from}&order_date_to=${order_date_to}&status=${status}&sort=${columnName}`;
  return queryService(config);
};

export const retrievedInvoiceByID = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/invoice/${id}`;
  return queryService(config);
};

export const createInvoice = (order_id, body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/invoice/${order_id}`;
  config.body = body;
  return queryService(config);
};

///////////////////////////////////////////////////////////////
/////////////////////Sales - Orders///////////////////////////
///////////////////////////////////////////////////////////////
export const retrievedOrders = (
  page = '1',
  limit = '10',
  sorted = 'purchase_date DESC',
  search = '',
  sMinRange,
  sMaxRange,
  sCusName,
  sOrdID,
  start_date,
  end_date,
  payment_method = '',
  filter_skus = '',
  customer_id = '',
  order_platform = '',
  or_number = '',
  or_date_from = '',
  or_date_to = '',
  number = ''
) => {
  let startDate = start_date ? `&start_date=${start_date}` : '';
  let endDate = start_date ? `&end_date=${end_date}` : '';
  let paymentMethod = payment_method ? `&payment_method=${payment_method}` : '';
  let or_number_filter = `&or_number=${or_number}`;
  let or_date_from_filter = `&or_date_from=${or_date_from}`;
  let or_date_to_filter = `&or_date_to=${or_date_to}`;

  const config = {};
  config.method = 'GET';
  config.route = `/order?page=${page}&limit=${limit}&min_range=${sMinRange}&max_range=${sMaxRange}&sort=${sorted}&name=${sCusName}&id=${sOrdID}${startDate}${endDate}&status=${search}&sku=${filter_skus}${paymentMethod}&customer_id=${customer_id}&order_platform=${order_platform}${or_number_filter}${or_date_from_filter}${or_date_to_filter}&number=${number}`;
  return queryService(config);
};

export const retrievedOrdersByID = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/order/${id}`;
  return queryService(config);
};

export const updateAddressOrder = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/order/address/${id}`;
  config.body = body;
  return queryService(config);
};

export const updateOrderStatus = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/order/status/${id}`;
  config.body = body;
  return queryService(config);
};

export const updateBypassOrderStatus = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/order/bypass_status/${id}`;
  config.body = body;
  return queryService(config);
};

export const updateOrInfo = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/order/or/${id}`;
  config.body = body;
  return queryService(config);
};

export const updateDirectShippingFee = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/order/shipping/${id}`;
  config.body = body;
  return queryService(config);
};

export const updateDirectItemPrice = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/order/price_update/${id}`;
  config.body = body;
  return queryService(config);
};

export const submitOrderComment = (id, body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/order/comment/${id}`;
  config.body = body;
  return queryService(config);
};

export const viewCommentHistoryOrder = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/order/comment/${id}`;
  return queryService(config);
};

export const retrievedOrdersStatuses = (status) => {
  const config = {};
  config.method = 'GET';
  config.route = `/order/status/available?status=${status}`;
  return queryService(config);
};

export const CheckPaymayaStatus = (body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/paymaya_update`;
  config.body = body;
  return queryService(config);
};

export const getListOfPaymentMethod = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/checkout/payment_method`;
  return queryService(config);
};

///////////////////////////////////////////////////////////////
/////////////////////Sales - Shipment///////////////////////////
///////////////////////////////////////////////////////////////

export const getAllSalesShipment = (
  page = '1',
  limit = '10',
  sort = '',
  status = '',
  minRange = 0,
  maxRange = Number.MAX_SAFE_INTEGER,
  shipto_name = '',
  ordID = '',
  shipment_start = '',
  shipment_end = '',
  order_start = '',
  order_end = '',
  shipment_id = ''
) => {
  let route = `/shipment?page=${page}&limit=${limit}&sort=${sort}&status=${status}&shipping_name=${shipto_name}&order_id=${ordID}`;
  route = `${route}&shipment_start_date=${shipment_start}&shipment_end_date=${shipment_end}&order_start_date=${order_start}&order_end_date=${order_end}`;
  const config = {};
  config.method = 'GET';
  config.route = `${route}&shipment_id=${shipment_id}&quantity_min=${minRange}&quantity_max=${maxRange}`;
  return queryService(config);
};

export const getSalesShipment = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/shipment/${id}`;
  return queryService(config);
};

export const createSalesShipment = (id, body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/shipment/${id}`;
  config.body = body;
  return queryService(config);
};

export const getShipmentTrackingInfo = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/shipment/track/${id}`;
  return queryService(config);
};

export const addShipmentTrackingInfo = (id, body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/shipment/track/${id}`;
  config.body = body;
  return queryService(config);
};

export const deleteShipmentTrackingInfo = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/shipment/track/${id}`;
  return queryService(config);
};

///////////////////////////////////////////////////////////////
/////////////////////Sales - Credit Memo///////////////////////////
///////////////////////////////////////////////////////////////

export const getAllSalesCreditMemo = (
  page = '1',
  limit = '10',
  sort = '',
  status = '',
  minRange = 0,
  maxRange = Number.MAX_SAFE_INTEGER,
  creditmemo_id = '',
  ordID = '',
  billing_name = '',
  created_start = '',
  created_end = '',
  order_start = '',
  order_end = ''
) => {
  const range = `${minRange}_${maxRange}`;
  const createdRange =
    created_start !== '' && created_end !== ''
      ? `${created_start}_${created_end}`
      : '';
  const orderRange =
    order_start !== '' && order_end !== '' ? `${order_start}_${order_end}` : '';
  let route = `/creditmemo?page=${page}&limit=${limit}&sort=${sort}&status=${status}&creditmemo_id=${creditmemo_id}&order_number=${ordID}&billing_name=${billing_name}`;
  route = `${route}&refunded=${range}&created=${createdRange}&order_date=${orderRange}`;
  const config = {};
  config.method = 'GET';
  config.route = route;
  return queryService(config);
};

export const getCreditMemoPerOrders = (
  id,
  page,
  limit,
  sort = '',
  status = '',
  minRange = 0,
  maxRange = Number.MAX_SAFE_INTEGER,
  creditmemo_id = '',
  ordID = '',
  billing_name = '',
  created_start = '',
  created_end = '',
  order_start = '',
  order_end = ''
) => {
  const range = `${minRange}_${maxRange}`;
  const createdRange =
    created_start !== '' && created_end !== ''
      ? `${created_start}_${created_end}`
      : '';
  const orderRange =
    order_start !== '' && order_end !== '' ? `${order_start}_${order_end}` : '';
  let route = `/creditmemo/${id}?page=${page}&limit=${limit}&sort=${sort}&status=${status}&creditmemo_id=${creditmemo_id}&order_number=${ordID}&billing_name=${billing_name}`;
  route = `${route}&refunded=${range}&created=${createdRange}&order_date=${orderRange}`;
  const config = {};
  config.method = 'GET';
  config.route = route;
  return queryService(config);
};

export const createCreditMemo = (id, body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/creditmemo/${id}`;
  config.body = body;
  return queryService(config);
};

export const getSalesMemo = (orderNum, memoID) => {
  const config = {};
  config.method = 'GET';
  config.route = `/creditmemo/${orderNum}/${memoID}`;
  return queryService(config);
};
///////////////////////////////////////////////////////////////
///////////Tickets History///////////////
///////////////////////////////////////////////////////////////
export const TicketsHistory = (id, page = '1', limit = '10', sort = '') => {
  const config = {};
  config.method = 'GET';
  config.route = `/ticket/history/${id}?page=${page}&limit=${limit}&sort=${sort}`;
  return queryService(config);
};

export const TicketsHistoryById = (id, historyID) => {
  const config = {};
  config.method = 'GET';
  config.route = `/ticket/history/${id}/${historyID}`;
  return queryService(config);
};

export const updateTicketHistory = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/tickets/${id}`;
  config.body = body;
  return queryService(config);
};

//////////////////////////////////////////////////
export const getShippingFee = (
  page,
  limit,
  province,
  provinceId,
  min,
  max,
  fID = '',
  fName = 'true',
  fFee = '',
  keyword = ''
) => {
  let sortID = `${fID}` ? `&sort_id=${fID}` : '';
  let sortName = `${fName}` ? `&sort_name=${fName}` : '';
  let sortFee = `${fFee}` ? `&sort_shipping_fee=${fFee}` : '';
  let query = `${keyword}` ? `&q=${keyword}` : '';
  const config = {};
  config.method = 'GET';
  config.route = `/rates?filter_name=${province}&filter_id=${provinceId}&filter_price_min=${min}&filter_price_max=${max}${sortID}${sortName}${sortFee}${query}`;
  return queryService(config);
};

export const getDefaultShipping = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/default_shipping`;
  return queryService(config);
};

export const updateShippingFee = (body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/rates`;
  config.body = body;
  return queryService(config);
};

///////////////////////////////////////////////////
//////// Get Logistics Companies //////////////////
///////////////////////////////////////////////////
export const getLogisticCompany = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/logistics/companies`;
  return queryService(config);
};
export const createLogisticCompany = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/logistics/companies`;
  config.body = body;
  return queryService(config);
};
export const deleteLogisticCompany = (company_id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/logistics/companies/${company_id}`;
  return queryService(config);
};

///////////////////////////////////////////////////
//////// Get Couriers Companies //////////////////
///////////////////////////////////////////////////
// export const getCompanyCourier = (company_id) => {
//   const config = {}
//   config.method = 'GET'
//   config.route = `/logistics/companies/${company_id}`
//   return queryService(config)
// }
// export const createCourier = (company_id, body) => {
//   const config = {}
//   config.method = 'POST'
//   config.route = `/logistics/companies/${company_id}/courier`
//   config.body = body
//   return queryService(config)
// }
//  delete courier

///////////////////////////////////////////////////////////////
///////////Tickets => Consignment/Authentication///////////////
///////////////////////////////////////////////////////////////
export const allTickets = (
  page = '',
  limit = '',
  sort = '', //for table
  ticketType = '',
  ticketStatus = '',
  consignorName = '', //filter
  keyword = '', // universal search
  bookingType = '',
  ticket_number = '',
  itemName = '',
  sku = '',
  bookingDate = '',
  date_created = '',
  bookingStatus = '',
  email_address = '',
  mobile_number = ''
) => {
  // filter queries
  let ticket_type = ticketType ? `&ticket_type=${ticketType}` : '';
  let serial_code = sku ? `&serial_code=${sku}` : '';
  let ticket_status = ticketStatus ? `&status=${ticketStatus}` : '';
  let consignor = consignorName
    ? `&consignor_name=${consignorName.trim()}`
    : '';
  let query = keyword ? `&q=${keyword}` : '';
  let booking_type = bookingType ? `&booking_type=${bookingType}` : '';
  let ticket_no = ticket_number ? `&ticket_no=${ticket_number}` : '';
  let model = itemName ? `&model=${itemName.trim()}` : '';
  let booking_date = bookingDate ? `&booking_date=${bookingDate}` : '';
  let created_at = date_created ? `&created_at=${date_created}` : '';
  let booking_status = bookingStatus ? `&booking_status=${bookingStatus}` : '';
  let email = email_address ? `&email=${email_address}` : '';
  let mobile = mobile_number ? `&number=${mobile_number}` : '';

  const config = {};
  config.method = 'GET';
  config.route = `/tickets?page=${page}&limit=${limit}&sort=${sort}${ticket_type}${ticket_status}${consignor}${query}${booking_type}${ticket_no}${model}${booking_date}${created_at}${booking_status}${email}${mobile}${serial_code}`;
  return queryService(config);
};

export const getTicket = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/tickets/${id}`;
  return queryService(config);
};

export const addTicket = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/tickets`;
  config.body = body;
  return queryService(config);
};

export const deleteTicket = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/tickets/${id}`;
  return queryService(config);
};

export const updateTicket = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/tickets/${id}/process`;
  config.body = body;
  return queryService(config);
};

///////////////////////////////////////////////////////////////
////////////////////////Attribute Set//////////////////////////
///////////////////////////////////////////////////////////////
export const allAttributeSet = (page = 0, limit = 10, query = '') =>
  fetch(
    `${api}/attribute-sets?limit=${limit}&offset=${page}&attribute_set_name=${query}`,
    {
      method: 'GET',
      headers,
    }
  ).then((res) => res.json());

export const getAttributeSet = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/attribute-sets/${id}`;
  return queryService(config);
};

export const deleteAttributeSet = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/attribute-sets/${id}`;
  return queryService(config);
};

export const updateAttributeSet = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/attribute-sets/${id}`;
  config.body = body;
  return queryService(config);
};

// tree representation on attribute set
export const updateAttributeSetGroup = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/attribute-groups/${id}`;
  config.body = body;
  return queryService(config);
};

export const deleteAttributeSetGroup = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/attribute-sets/groups/${id}`;
  return queryService(config);
};

export const createGroup = (id, body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/attribute-sets/${id}`;
  config.body = body;
  return queryService(config);
};

export const moveChildToOtherGroup = (entityId, groupID) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/attribute-entity/${entityId}`;
  config.body = { attribute_group_id: groupID };
  return queryService(config);
};
export const deleteAttribute = (entityId) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/attribute-sets/entity/${entityId}`;
  return queryService(config);
};
export const addAttributeToSet = (attributeId, groupID, body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/attribute-sets/${attributeId}/groups/${groupID}`;
  config.body = body;
  return queryService(config);
};

///////////////////////////////////////////////////////////////
////////////////////////All Customer///////////////////////////
///////////////////////////////////////////////////////////////
export const allCustomer = (
  page = '1',
  limit = '10',
  sorting = '',
  firstname = '',
  lastname = '',
  email = '',
  q = '',
  date,
  customerId = '',
  is_active = '',
  is_guest = '',
  group = ''
) => {
  const query = q ? `&q=${q}` : '';
  const first_name = firstname ? `&firstname=${firstname}` : '';
  const last_name = lastname ? `&lastname=${lastname}` : '';
  const customer_id = customerId ? `&id=${customerId}` : '';
  const customer_active = is_active ? `&is_active=${is_active}` : '';
  const customer_email = email ? `&email=${email}` : '';
  const customer_since = date ? `&date=${date}` : '';
  const sort = sorting ? `&sort=${sorting}` : '';
  const customer_group = group ? `&customer_group=${group}` : '';
  const customer_guest = is_guest ? `&is_guest=${is_guest}` : '';
  const config = {};
  config.method = 'GET';
  config.route = `/customers?page=${page}&limit=${limit}${sort}${first_name}${last_name}${customer_email}${query}${customer_since}${customer_id}${customer_active}${customer_group}${customer_guest}`;
  return queryService(config);
};
export const getOneCustomer = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/customers/${id}`;
  return queryService(config);
};
export const createCustomer = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/customer`;
  config.body = body;
  return queryService(config);
};
export const updateCustomer = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/customers/${id}`;
  config.body = body;
  return queryService(config);
};

export const deleteCustomer = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/customers/${id}`;
  return queryService(config);
};

export const getAllProvinces = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/address/provinces`;
  return queryService(config);
};

export const getAllMunicipalitiesWithBarangay = (code) => {
  const config = {};
  config.method = 'GET';
  config.route = `/address/municipality/${code}`;
  return queryService(config);
};

export const getAllBarangayByMunicipalityCode = (code) => {
  const config = {};
  config.method = 'GET';
  config.route = `/address/barangay/${code}`;
  return queryService(config);
};

export const getAllWishLists = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/favorite`;
  return queryService(config);
};

export const updateCustomerStatus = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/customers/status/${id}`;
  config.body = body;
  return queryService(config);
};

///////////////////////////////////////////////////////////////
////////////////////////Customer Group/////////////////////////
///////////////////////////////////////////////////////////////
export const getAllCustomerGroup = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/customers/group?page=1&limit=9999`;
  return queryService(config);
};

export const allCustomerGroup = (
  page = '1',
  limit = '10',
  sort,
  is_active = '1',
  group_id = '',
  group_code = ''
) => {
  let token = Cookie.getToken();

  const headers = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token.token}` : '',
    'Content-Type': 'application/json',
  };
  return fetch(
    `${api}/customers/group?page=${page}&limit=${limit}&sort=${sort}&is_active=${is_active}&group_id=${group_id}&group_code=${group_code}`,
    {
      method: 'GET',
      headers,
    }
  ).then((res) => res.json());
};

export const getOneCustomerGroup = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/customers/group/${id}`;
  return queryService(config);
};

export const createCustomerGroup = (body) => {
  let token = Cookie.getToken();
  const headers = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token.token}` : '',
    'Content-Type': 'application/json',
  };
  return fetch(`${api}/customers/group`, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  }).then((res) => res.json());
};
export const updateCustomerGroup = (id, body) => {
  let token = Cookie.getToken();
  const headers = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token.token}` : '',
    'Content-Type': 'application/json',
  };
  return fetch(`${api}/customers/group/${id}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(body),
  }).then((res) => res.json());
};

export const deleteCustomerGroup = (id) => {
  let token = Cookie.getToken();
  const headers = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token.token}` : '',
    'Content-Type': 'application/json',
  };
  return fetch(`${api}/customers/group?id=${id}`, {
    method: 'DELETE',
    headers,
  }).then((res) => res.json());
};
export const searchCustomerGroup = (id, name, sort, is_active = '1') => {
  let token = Cookie.getToken();
  const headers = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token.token}` : '',
    'Content-Type': 'application/json',
  };
  return fetch(
    `${api}/customers/group?group_id=${id}&group_code=${name}&sort=${sort}&is_active=${is_active}`,
    {
      method: 'GET',
      headers,
    }
  ).then((res) => res.json());
};

/* ALL USERS */
export const getAllAdmins = (
  username,
  firstname,
  lastname,
  email,
  user_id,
  status,
  order,
  page,
  limit,
  rolename = ''
) => {
  const config = {};
  config.method = 'GET';
  config.route = `/admins?username=${username}&firstname=${firstname}&lastname=${lastname}&email=${email}&role_name=${rolename}&user_id=${user_id}&is_active=${status}&order=${order}&page=${page}&limit=${limit}`;
  return queryService(config);
};

export const getAdminByID = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/admins/${id}`;
  return queryService(config);
};
export const postAdminByID = (body) => {
  const config = {};
  config.body = body;
  config.method = 'POST';
  config.route = `/admins`;
  return queryService(config);
};
export const putAdminByID = (body, id) => {
  const config = {};
  config.body = body;
  config.method = 'PUT';
  config.route = `/admins/${id}`;
  return queryService(config);
};
export const deleteAdminByID = (body, id) => {
  const config = {};
  config.body = body;
  config.method = 'DELETE';
  config.route = `/admins/${id}`;
  return queryService(config);
};

/* ALL RESOURCES */

export const getProductResources = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/product/resource`;
  return queryService(config);
};

export const getAllResources = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/resources`;
  return queryService(config);
};

/* ALL ROLES */

export const getAllRoles = (page, pageSize) => {
  const config = {};
  config.method = 'GET';
  config.route = `/roles?page=${page}&limit=${pageSize}`;
  return queryService(config);
};

export const getRoleByID = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/roles/${id}`;
  return queryService(config);
};
export const postRole = (body) => {
  const config = {};
  config.body = body;
  config.method = 'POST';
  config.route = `/roles`;
  return queryService(config);
};
export const putRole = (body, id) => {
  const config = {};
  config.body = body;
  config.method = 'PUT';
  config.route = `/roles/${id}`;
  return queryService(config);
};
export const deleteRole = (body, id) => {
  const config = {};
  config.body = body;
  config.method = 'DELETE';
  config.route = `/roles/${id}`;
  return queryService(config);
};

/* ATTRIBUTE SET */

export const getAllAttributeSet = () => {
  const config = {};
  config.method = 'GET';
  config.route = '/attribute-sets';
  return queryService(config);
};

/* UPLOAD IMAGE */
export const uploadImage = (body, id, type) => {
  let content_type = type ? `content_type=${type}` : '';

  const fd = new FormData();
  fd.append('file', body);
  return fetch(`${api}/storage/upload/${id}?${content_type}`, {
    method: 'POST',
    headers: {},
    body: fd,
  }).then((res) => {
    return res.json();
  });
};

/* CATEGORY */

export const unlinkCategoryProducts = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/category/bulk/${id}`;
  return queryService(config);
};

export const getAllCategory = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/category`;
  return queryService(config);
};
export const getCategoryByID = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/category/${id}`;
  return queryService(config);
};
export const deleteCategoryByID = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/category/${id}`;
  return queryService(config);
};

export const putCategoryByID = (body, id) => {
  const config = {};
  config.method = 'PUT';
  config.body = body;
  config.route = `/category/${id}`;
  return queryService(config);
};

export const postCategoryByID = (body) => {
  const config = {};
  config.method = 'POST';
  config.body = body;
  config.route = `/category`;
  return queryService(config);
};

export const getCategoryProducts = (id, limit = 10, offset = 1) => {
  const config = {};
  config.method = 'GET';
  config.route = `/products/category/${id}?limit=${limit}&offset=${offset}&location_id=67f0538c-e468-4c2f-95c9-45008e0b1b22`;
  return queryService(config);
};
/* EDITORIAL */

export const getBrandsForProducts = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/brands?limit=9999&filter_by=name`;
  return queryService(config);
};
export const getBrandAndCategoryID = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/brand_id/${id}`;
  return queryService(config);
};
export const getEditorialByID = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/editorial/${id}`;
  return queryService(config);
};
export const getEditorialData = (id, code) => {
  const config = {};
  config.method = 'GET';
  config.route = `/editorial/${id}/${code}`;
  return queryService(config);
};

export const updateProductManual = (body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/editorial`;
  config.body = body;
  return queryService(config);
};
export const postProductManual = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/add_product`;
  config.body = body;
  return queryService(config);
};
/* LOCATION */

export const getLocation = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/inventory/location?limit=9999`;
  return queryService(config);
};
/* COUPONS */

export const getCoupons = (
  search = '',
  is_enabled = '',
  sort = '',
  page,
  pageSize
) => {
  const config = {};
  config.method = 'GET';
  config.route = `/coupons?q=${search}&is_enabled=${is_enabled}&sort=${sort}&page=${page}&limit=${pageSize}`;
  return queryService(config);
};
export const getCouponsById = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/coupons/${id}`;
  return queryService(config);
};
export const postCoupons = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/coupons`;
  config.body = body;
  return queryService(config);
};
export const updateCoupons = (body, id) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/coupons/${id}`;
  config.body = body;
  return queryService(config);
};
export const deleteCoupons = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/coupons/${id}`;
  return queryService(config);
};

/* PRODUCTS */
const type = `{"attributes" : [
  "category",
  "visibility",
  "status",
  "mhero_internal_status",
  "quantity",
  "is_enabled",
  "small_image_label",
  "mhero_loan_value",
  "auth"
]}`;
export const getProducts = (
  limit = 10,
  offset = 1,
  name = '',
  sku = '',
  priceMin,
  priceMax,
  location,
  status,
  is_enabled,
  auth,
  stock,
  sortBy,
  sort,
  backType,
  date_created,
  categoryFilter,
  promo = ''
) => {
  // console.log("min : ", priceMin," - ", "max :", priceMax)
  let stockx = location !== '67f0538c-e468-4c2f-95c9-45008e0b1b22' ? true : '';
  let stockFilter = `&with_stock=${stock}`;
  let sorted = sortBy
    ? `&sort=[{ "attribute_id": "${sortBy}", "backend_type": "${backType}", "order": ${sort} }]`
    : '';
  // console.log(limit, offset, name, sku, priceMin, priceMax, location, status, is_enabled, stock, stockFilter)
  const filter = [
    {
      attribute_code: 'name',
      backend_type: 'varchar',
      operator: 'like',
      value: `${name}`,
    },
    {
      attribute_code: 'sku',
      backend_type: 'static',
      operator: 'like',
      value: `${sku}`,
    },
    {
      attribute_code: 'mhero_internal_status',
      backend_type: 'varchar',
      operator: 'like',
      value: `${status}`,
    },
  ];
  const price0 = priceMin !== undefined || priceMin !== '' ? priceMin : '';
  const price1 = priceMax !== undefined || priceMax !== '' ? priceMax : '';

  if (price0 && price1) {
    filter.push({
      attribute_code: 'price',
      backend_type: 'decimal',
      operator: 'between',
      value: [priceMin, priceMax],
    });
  } else if (price0 && !price1) {
    filter.push({
      attribute_code: 'price',
      backend_type: 'decimal',
      operator: 'between',
      value: [priceMin, Number.MAX_SAFE_INTEGER],
    });
  } else if (!price0 && price1) {
    filter.push({
      attribute_code: 'price',
      backend_type: 'decimal',
      operator: 'between',
      value: [0, priceMax],
    });
  }

  if (date_created) {
    filter.push({
      attribute_code: 'created_at',
      backend_type: '',
      operator: 'between',
      value: date_created,
    });
  }

  is_enabled !== '' &&
    filter.push({
      attribute_code: 'status',
      backend_type: 'int',
      operator: '=',
      value: `${is_enabled}`,
    });

  if (auth && auth !== '' && auth !== 'undefined') {
    // if(auth == "Authentic"){
    //   filter.push({ attribute_code: 'auth', backend_type: 'varchar', operator: 'in', value: [ null, `${auth}` ] });
    // }else if(auth == "Fake"){
    //   filter.push({ attribute_code: 'auth', backend_type: 'varchar', operator: '=', value: `${auth}` });
    // }else {
    filter.push({
      attribute_code: 'auth',
      backend_type: 'varchar',
      operator: '=',
      value: `${auth}`,
    });
    // }
  }

  let filterString = JSON.stringify(filter);
  const config = {};
  config.method = 'GET';
  config.route = `/products?promo=${promo}&category_id=${categoryFilter}&additional_attributes=${type}&limit=${limit}&offset=${offset}&filter=${filterString}&location_id=${location}${
    stockx ? `&with_stock=true` : stock !== '' ? stockFilter : ''
  }${sorted}`;
  return queryService(config);
};

export const getProductsV2 = (
  limit = 10,
  offset = 1,
  name = '',
  sku = '',
  priceMin,
  priceMax,
  location,
  status,
  is_enabled,
  auth,
  stock,
  sortBy,
  sort,
  backType,
  date_created,
  categoryFilter,
  promo = ''
) => {
  // console.log("min : ", priceMin," - ", "max :", priceMax)
  let stockx = location !== '67f0538c-e468-4c2f-95c9-45008e0b1b22' ? true : '';
  let stockFilter = `&with_stock=${stock}`;
  let sorted = sortBy
    ? `&sort=[{ "attribute_id": "${sortBy}", "backend_type": "${backType}", "order": ${sort} }]`
    : '';
  // console.log(limit, offset, name, sku, priceMin, priceMax, location, status, is_enabled, stock, stockFilter)
  const filter = [
    {
      attribute_code: 'name',
      backend_type: 'varchar',
      operator: 'like',
      value: `${name}`,
    },
    {
      attribute_code: 'sku',
      backend_type: 'static',
      operator: 'like',
      value: `${sku}`,
    },
    {
      attribute_code: 'mhero_internal_status',
      backend_type: 'varchar',
      operator: 'like',
      value: `${status}`,
    },
  ];
  const price0 = priceMin !== undefined || priceMin !== '' ? priceMin : '';
  const price1 = priceMax !== undefined || priceMax !== '' ? priceMax : '';

  if (price0 && price1) {
    filter.push({
      attribute_code: 'price',
      backend_type: 'decimal',
      operator: 'between',
      value: [priceMin, priceMax],
    });
  } else if (price0 && !price1) {
    filter.push({
      attribute_code: 'price',
      backend_type: 'decimal',
      operator: 'between',
      value: [priceMin, Number.MAX_SAFE_INTEGER],
    });
  } else if (!price0 && price1) {
    filter.push({
      attribute_code: 'price',
      backend_type: 'decimal',
      operator: 'between',
      value: [0, priceMax],
    });
  }

  if (date_created) {
    filter.push({
      attribute_code: 'created_at',
      backend_type: '',
      operator: 'between',
      value: date_created,
    });
  }

  is_enabled !== '' &&
    filter.push({
      attribute_code: 'status',
      backend_type: 'int',
      operator: '=',
      value: `${is_enabled}`,
    });

  if (auth && auth !== '' && auth !== 'undefined') {
    // if(auth == "Authentic"){
    //   filter.push({ attribute_code: 'auth', backend_type: 'varchar', operator: 'in', value: [ null, `${auth}` ] });
    // }else if(auth == "Fake"){
    //   filter.push({ attribute_code: 'auth', backend_type: 'varchar', operator: '=', value: `${auth}` });
    // }else {
    filter.push({
      attribute_code: 'auth',
      backend_type: 'varchar',
      operator: '=',
      value: `${auth}`,
    });
    // }
  }

  let filterString = JSON.stringify(filter);
  const config = {};
  config.method = 'GET';
  config.route = `/productsv2?promo=${promo}&category_id=${categoryFilter}&additional_attributes=${type}&limit=${limit}&offset=${offset}&filter=${filterString}&location_id=${location}${
    stockx ? `&with_stock=true` : stock !== '' ? stockFilter : ''
  }${sorted}`;
  return queryService(config);
};

export const getALLProducts = (limit = Number.MAX_SAFE_INTEGER) => {
  let token = Cookie.getToken();
  const headers = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token.token}` : '',
    'Content-Type': 'application/json',
  };
  return fetch(
    `${api}/products?location_id=67f0538c-e468-4c2f-95c9-45008e0b1b22&limit=${limit}`,
    {
      method: 'GET',
      headers,
    }
  ).then((res) => res.json());
};

export const getProductByID = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/products/${id}`;
  return queryService(config);
};

export const deleteProductByID = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/products/${id}`;
  return queryService(config);
};

/* CATALOG PRICE RULE */

export const getAllPriceRule = () => {
  const config = {};
  config.method = 'GET';
  config.route = '/catalog-rule/';
  return queryService(config);
};
export const getPriceRuleByID = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/catalog-rule/${id}`;
  return queryService(config);
};

/* TAX CLASS */

export const getAllTax = () => {
  const config = {};
  config.method = 'GET';
  config.route = '/data-management/tax-class';
  return queryService(config);
};
export const postTaxClass = (body) => {
  const config = {};
  config.body = body;
  config.method = 'POST';
  config.route = '/data-management/tax-class';
  return queryService(config);
};
export const putTaxClass = (body, id) => {
  const config = {};
  config.body = body;
  config.method = 'PUT';
  config.route = `/data-management/tax-class/${id}`;
  return queryService(config);
};
export const deleteTaxClass = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/data-management/tax-class/${id}`;
  return queryService(config);
};

// USER
export const getAllUsers = () => {
  const config = {};
  config.method = 'GET';
  config.route = '/user';
  return queryService(config);
};

export const createUser = (body) => {
  const config = {};
  config.body = body;
  config.method = 'POST';
  config.route = '/user';
  return queryService(config);
};

export const deleteUser = (id) => {
  const config = {
    method: 'DELETE',
    route: `/user/${id}`,
  };
  return queryService(config);
};

// PRODUCT

export const remove = (id) => {
  const config = {
    method: 'DELETE',
    route: `/contacts/${id}`,
  };
  return queryService(config);
};

export const create = (body) => {
  const config = {};
  config.body = body;
  config.method = 'POST';
  config.route = '/contacts';
  return queryService(config);
};

/////////////////////////////////////////////
/////////////// Setting SMS /////////////////
/////////////////////////////////////////////

export const getAllSMS = (page, limit, search, sortvalue) => {
  const config = {};
  config.method = 'GET';
  config.route = `/settings/sms?page=${page}&limit=${limit}&search=${search}&sort=${sortvalue}`;
  return queryService(config);
};

export const getSMS = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/settings/sms/${id}`;
  return queryService(config);
};

export const updateSMS = (id, body) => {
  const config = {};
  config.body = body;
  config.method = 'PUT';
  config.route = `/settings/sms/${id}`;
  return queryService(config);
};

export const updateAllStatusSMS = (body) => {
  const config = {};
  config.method = 'PUT';
  config.body = body;
  config.route = `/settings/status/sms/`;
  return queryService(config);
};

export const updateStatusSMS = (body, id) => {
  const config = {};
  config.method = 'PUT';
  config.body = body;
  config.route = `/settings/status/sms/${id}`;
  return queryService(config);
};

/////////////////////////////////////////////
/////////////// Setting SNS /////////////////
/////////////////////////////////////////////

export const getAllSNSData = (
  page,
  limit,
  sort_by,
  sort,
  type = '',
  description = '',
  content = '',
  is_active = ''
) => {
  const config = {};
  config.method = 'GET';
  config.route = `/sns?page=${page}&limit=${limit}&sort_by=${sort_by}&sort=${sort}&type=${type}&description=${description}&content=${content}&is_active=${is_active}`;
  return queryService(config);
};

export const getSNSByID = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/sns/${id}`;
  return queryService(config);
};

export const updateSNS = (id, body) => {
  const config = {};
  config.body = body;
  config.method = 'PUT';
  config.route = `/sns/${id}`;
  return queryService(config);
};

export const updateAllStatusSNS = (body) => {
  const config = {};
  config.method = 'PUT';
  config.body = body;
  config.route = `/sns/status`;
  return queryService(config);
};

export const updateStatusSNS = (body, id) => {
  const config = {};
  config.method = 'PUT';
  config.body = body;
  config.route = `/sns/status/${id}`;
  return queryService(config);
};

/////////////////////////////////////////////
/////////////// Setting Email ///////////////
/////////////////////////////////////////////

export const getAllEmail = (page, limit, search, sortvalue) => {
  const config = {};
  config.method = 'GET';
  config.route = `/settings/email?page=${page}&limit=${limit}&search=${search}&sort=${sortvalue}`;
  return queryService(config);
};

export const getEmailByID = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/settings/email/${id}`;
  return queryService(config);
};

export const updateEmail = (id, body) => {
  const config = {};
  config.body = body;
  config.method = 'PUT';
  config.route = `/settings/email/${id}`;
  return queryService(config);
};

export const updateStatusEmail = (body, id) => {
  const config = {};
  config.method = 'PUT';
  config.body = body;
  config.route = `/settings/status/email/${id}`;
  return queryService(config);
};

export const updateAllStatusEmail = (body) => {
  const config = {};
  config.method = 'PUT';
  config.body = body;
  config.route = `/settings/status/email/`;
  return queryService(config);
};

export const resetEmailContent = (id) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/settings/reset/email/${id}`;
  return queryService(config);
};

/////////////////////////////////////////////
/////////////// Audit Trail /////////////////
/////////////////////////////////////////////

export const getAllSalesAuditTrail = (
  page,
  limit,
  sortvalue,
  updated_by,
  order_number,
  changeLog,
  date_created,
  date_updated,
  search_sku = ''
) => {
  let route;
  route = `/audit_trail/sales?page=${page}&limit=${limit}&sort=${sortvalue}&order_number=${order_number}&updated_by=${updated_by}`;
  route = `${route}&change_log=${changeLog}&date_created=${date_created}&date_updated=${date_updated}&sku=${search_sku}`;
  const config = {};
  config.method = 'GET';
  config.route = route;
  return queryService(config);
};

export const getAllCatalogAuditTrail = (
  page,
  limit,
  sortvalue,
  submodule,
  entity_id,
  date_updated,
  updated_by,
  old_value,
  new_value,
  change_log,
  sku
) => {
  let route;
  route = `/audit_trail/catalog?page=${page}&limit=${limit}&sort=${sortvalue}&submodule=${submodule}&entity_id=${entity_id}&date_updated=${date_updated}`;
  route = `${route}&updated_by=${updated_by}&old_value=${old_value}&new_value=${new_value}&change_log=${change_log}&sku=${sku}`;
  const config = {};
  config.method = 'GET';
  config.route = route;
  return queryService(config);
};

export const getAllCustomerAuditTrail = (
  page,
  limit,
  sortvalue,
  submodule,
  date_updated,
  updated_by,
  old_value,
  new_value,
  change_log
) => {
  let route;
  route = `/audit_trail/customers?page=${page}&limit=${limit}&sort=${sortvalue}&submodule=${submodule}&updated=${date_updated}`;
  route = `${route}&user=${updated_by}&old_value=${old_value}&new_value=${new_value}&change_log=${change_log}`;
  const config = {};
  config.method = 'GET';
  config.route = route;
  return queryService(config);
};
export const getAllContentAuditTrail = (
  page,
  limit,
  sortValue,
  submodule,
  entity_id,
  updatedBy,
  change_log,
  oldValueFrom,
  newValueTo,
  created_from,
  created_to
) => {
  // console.log(created_from, created_to)
  let route;
  route = `/audit_trail/content?offset=${page}&limit=${limit}&sort_by=${sortValue.id}&sort=${sortValue.desc}&submodule=${submodule}&entity_id=${entity_id}&user=${updatedBy}`;
  route = `${route}&change_log=${change_log}&old_value=${oldValueFrom}&new_value=${newValueTo}&created_from=${created_from}&created_to=${created_to}`;
  const config = {};
  config.method = 'GET';
  config.route = route;
  return queryService(config);
};

export const getAllNotifAuditTrail = (
  page,
  limit,
  sortvalue,
  submodule,
  entity_id,
  date_updated,
  updated_by,
  old_value,
  new_value,
  change_log
) => {
  let route;
  route = `/audit_trail/notifications?page=${page}&limit=${limit}&sort=${sortvalue}&submodule=${submodule}&entity_id=${entity_id}&date_updated=${date_updated}`;
  route = `${route}&updated_by=${updated_by}&old_value=${old_value}&new_value=${new_value}&change_log=${change_log}`;
  const config = {};
  config.method = 'GET';
  config.route = route;
  return queryService(config);
};

export const getAllTicketAuditTrail = (
  page = 1,
  limit = 10,
  updated = '',
  user = '',
  change_log = '',
  old_value = '',
  new_value = '',
  sort = ''
) => {
  let route;
  route = `/audit_trail/tickets?page=${page}&limit=${limit}&updated=${updated}&user=${user}&`;
  route = `${route}change_log=${change_log}&old_value=${old_value}&new_value=${new_value}&sort=${sort}`;
  const config = {};
  config.method = 'GET';
  config.route = route;
  return queryService(config);
};

/////////////////////////////////////////////
/////////////// Maintenance /////////////////
/////////////////////////////////////////////

/////////////////////////////////////////////
/////////////// URL Rewrite /////////////////
/////////////////////////////////////////////

export const postURLRewrite = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/url_rewrite`;
  config.body = body;
  return queryService(config);
};
export const deleteURLRewrite = (id, type) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/url_rewrite?entity_type=${type}&entity_id=${id}`;
  return queryService(config);
};
export const getURLrewrites = (
  offset,
  limit,
  entity_type = '',
  target_path = '',
  name = '',
  sku = ''
) => {
  const config = {};
  config.method = 'GET';
  config.route = `/url_rewrite?limit=${limit}&offset=${offset}&entity_type=${entity_type}&target_path=${target_path}&name=${name}&sku=${sku}`;
  // config.form_data = true
  return queryService(config);
};

export const getURLrewrite = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/url_rewrite/${id}`;
  config.form_data = true;

  return queryService(config);
};

export const updateURLrewrite = (body) => {
  let token = Cookie.getToken();
  // console.log(token.token)
  // const headers = {
  //   'Authorization': token ? `Bearer ${token.token}` : '',
  //   'Content-Type': 'application/x-www-form-urlencoded'
  // }
  const headers = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token.token}` : '',
    'Content-Type': 'application/json',
  };

  return fetch(`${api}/request_path`, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  }).then((res) => res.json());

  // debugger
  // const config = {}
  // config.method = "POST"
  // config.route = '/request_path'
  // config.body = body

  // return queryService(config)
};

export const getMaintenanceSched = () => {
  const config = {};
  config.method = 'GET';
  config.route = '/maintenance';
  return queryService(config);
};

export const setMaintenanceSched = (body) => {
  const config = {};
  config.method = 'PUT';
  config.route = '/maintenance';
  config.body = body;
  return queryService(config);
};

/////////////////////////////////////////////
/////////////// Seting Audit Trail /////////////////
/////////////////////////////////////////////

export const getSettingAuditTrail = (
  limit,
  page,
  date_start,
  date_end,
  submodule,
  user,
  entity_id,
  change_log,
  old_value,
  new_value,
  columnToSort
) => {
  let route = '';

  // date_start, date_end
  route = `/settings/audit_trail?page=${page}&limit=${limit}&submodule=${submodule}&entity_id=${entity_id}&user=${user}`;
  route = `${route}&change_log=${change_log}&date_start=${date_start}&date_end=${date_end}&old_value=${old_value}&new_value=${new_value}&sort=${columnToSort}`;

  // route = `/settings/audit_trail?limit=${limit}&page=${page}&search=${search}${submodule && `&submodule=${submodule}`}${date_start && `&date_start=${date_start}`}${date_end && `&date_end=${date_end}`}`
  // route = `${route}&change_log=${changeLog}&date_created=${date_created}&date_updated=${date_updated}`
  const config = {};
  config.method = 'GET';
  config.route = route;

  return queryService(config);
};

function queryService({
  route,
  method,
  body,
  params,
  cache = false,
  form_data = false,
}) {
  // const fd = new FormData ();
  const token = Cookie.getToken();
  // 'Authorization': token ? `Bearer ${token.token}` : '',
  const new_headers = form_data
    ? {
        // 'x-access-token': Cookie.getToken()
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: token ? `Bearer ${token.token}` : '',
        // , 'content-type': 'multipart/form-data'
      }
    : {
        ...headers,
        'x-access-token': Cookie.getToken(),
        Authorization: token ? `Bearer ${token.token}` : '',
      };
  const def_body = body ? JSON.stringify(body) : null;
  // console.log(abortController.signal)
  const new_body = form_data
    ? // fd.append('file', body )
      new_body
    : def_body;
  // const serializeParams = params ? (serializeUrl(params)) : '';
  // const t = !cache ? `?t=${new Date().getTime()}` : '?'
  // return fetch(`${api}${route}${t}${serializeParams}`, {
  // console.log(new_headers,route)
  return fetch(
    `${api}${route}`,
    {
      method,
      headers: { ...new_headers },
      body: new_body,
      // signal: abortController.signal
    },
    5
  )
    .then((res) => {
      if (res.ok) return res ? res.json() : {};
      else {
        const json = res.json();
        throw json;
      }
    })
    .catch((err = {}) => {
      if (err.then)
        return err.then(function (data) {
          throw data.error;
        });
      else {
        const error = [
          {
            code: 'ZERO_RES',
            message: 'Connection Refuse',
            context: 'Please check your internet connection.',
          },
        ];
        throw error;
      }
    });
}

function serializeUrl(obj, prefix) {
  function start() {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + '[' + p + ']' : p,
          v = obj[p];
        if (v !== '' && typeof v !== 'undefined')
          str.push(
            v !== null && typeof v === 'object'
              ? serializeUrl(v, k)
              : encodeURIComponent(k) + '=' + encodeURIComponent(v)
          );
      }
    }
    return str.join('&');
  }

  const start_value = start();
  return '&' + start_value;
}

export const postCart = (body, customer_id) => {
  const config = {};
  config.method = 'POST';
  config.route = `/cart/items?customer_id=${customer_id}`;
  config.body = body;
  return queryService(config);
};

export const getCart = (quote_id, customer_id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/cart/${quote_id}?customer_id=${customer_id}`;
  return queryService(config);
};

export const getCartByQuote = (quote_id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/cart/${quote_id}`;
  return queryService(config);
  // 438
};

export const getWishlist = (customer_id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/wishlist-products/?customer_id=${customer_id}`;
  return queryService(config);
};

export const getRates = (
  filter_name = '',
  filter_id = '',
  filter_price_min = 0,
  filter_price_max = Number.MAX_SAFE_INTEGER,
  sort_id = true,
  sort_name = false,
  sort_shipping_fee = true,
  q = ''
) => {
  const config = {};
  config.method = 'GET';
  config.route = `/rates?filter_name=${filter_name}&filter_id=${filter_id}&filter_price_min=${filter_price_min}&filter_price_max=${filter_price_max}&sort_id=${sort_id}&sort_name=${sort_name}&sort_shipping_fee=${sort_shipping_fee}&q=${q}`;
  return queryService(config);
};

export const getRateByRegion = (region_id = null) => {
  const config = {};
  config.method = 'GET';
  config.route = `/rate?region_id=${region_id}`;
  return queryService(config);
};

export const getListOfPaymentMethodByRegion = (region_id) => {
  let token = Cookie.getToken();
  const headers = {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token.token}` : '',
    'Content-Type': 'application/json',
  };
  return fetch(`${api}/checkout/payment_method?region_id=${region_id}`, {
    method: 'GET',
    headers,
  }).then((res) => res.json());
};

export const updateCheckoutAddress = (body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/checkout`;
  config.body = body;
  return queryService(config);
};

export const postPaymentMethod = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/checkout/payment_method`;
  config.body = body;
  return queryService(config);
};

export const checkCartAvailability = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/checkout/availability`;
  config.body = body;
  return queryService(config);
};

export const removeByIDFromCart = (product_id, customer_id, body) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/remove_cart/${product_id}?customer_id=${customer_id}`;
  config.body = body;
  return queryService(config);
};

export const submitPayment = (body, backorder_shipping_fee) => {
  const config = {};
  config.method = 'POST';
  config.route = `/payment_submit`;
  config.body = body;
  return queryService(config);
};

export const postCheckout = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/checkout`;
  config.body = body;
  return queryService(config);
};

export const getAllPromotionalEmail = (
  limit,
  page,
  sort = 'id-ASC',
  subject = '',
  description = '',
  modified_by = '',
  is_active = 1
) => {
  const config = {};
  config.method = 'GET';
  config.route = `/promotional?limit=${limit}&page=${page}&sort=${sort}&subject=${subject}&description=${description}&modified_by=${modified_by}&is_active=${is_active}`;
  return queryService(config);
};

export const getPromotionalEmailByID = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/promotional/${id}`;
  return queryService(config);
};

export const addPromotionalEmail = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/promotional`;
  config.body = body;
  return queryService(config);
};

export const updatePromotionalEmail = (id, body) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/promotional/${id}`;
  config.body = body;
  return queryService(config);
};

export const deletePromotionalEmail = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/promotional/${id}`;
  return queryService(config);
};

export const triggerPromotionalEmail = (id) => {
  const config = {};
  config.method = 'POST';
  config.route = `/promotional/trigger/${id}`;
  return queryService(config);
};

export const clearAdminCart = (customer_id, body) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/clear_cart?customer_id=${customer_id}`;
  config.body = body;
  return queryService(config);
};

export const updatePriceOnly = (product_id, body, flag = 0) => {
  //flag 1 = special price, 0 = normal price
  const config = {};
  config.method = 'PUT';
  config.route = `/price/${product_id}/${flag}`;
  config.body = body;
  return queryService(config);
};

// WAREHOUSE REQUESTS
export const getAllWarehouseAuditTrail = (
  page,
  limit,
  sortvalue = 'date_updated-desc',
  submodule = '',
  number = '',
  sku = '',
  code = '',
  location = '',
  wd_type = '',
  wd_date = '',
  mv_date = '',
  items_moved_by = '',
  requested_by = '',
  received_by = '',
  returned_by = '',
  returned_date = '',
  date_updated = '',
  updated_by = '',
  change_log = '',
  old_value = '',
  new_value = ''
) => {
  let route = `/audit_trail/wms?page=${page}&limit=${limit}&submodule=${submodule}&number=${number.trim()}&moved_by=${items_moved_by.trim()}&location_code=${code.trim()}&sku=${sku.trim()}&location_name=${location}&wd_type=${wd_type}&wd_date=${wd_date}&mv_date=${mv_date}&requested_by=${requested_by.trim()}&received_by=${received_by.trim()}&returned_by=${returned_by.trim()}&returned_date=${returned_date}&date_updated=${date_updated}&updated_by=${updated_by.trim()}&changelog=${change_log.trim()}&old_value=${old_value}&new_value=${new_value}&sort=${sortvalue}`;
  const config = {};
  config.method = 'GET';
  config.route = route;
  return queryService(config);
};

// WAREHOUSE Movement
export const searchProductSku = (sku = '', stock_type = '') => {
  const is_withdrawal = Help.searchSlipNum(sku, /WD-[0-9{6}]+-[0-9{4}]+/);
  let route = `/wms/products?sku=${
    is_withdrawal ? '' : sku
  }&inside=${stock_type}&withdrawal_number=${is_withdrawal ? sku : ''}`;
  const config = {};
  config.method = 'GET';
  config.route = route;
  return queryService(config);
};
export const getAllWarehouseMovement = (
  page,
  limit,
  sortvalue = 'date_updated-desc',
  sku = '',
  movement_number = '',
  movement_date = '',
  items_moved_by = '',
  requested_by = '',
  status = '',
  date_created = '',
  created_by = ''
) => {
  let route = `/wms/movements?page=${page}&limit=${limit}&sku=${sku}&movement_number=${movement_number}&movement_date=${movement_date}&items_moved_by=${items_moved_by}&requested_by=${requested_by}&status=${status}&date_created=${date_created}&created_by=${created_by}&sort=${sortvalue}`;
  const config = {};
  config.method = 'GET';
  config.route = route;
  return queryService(config);
};
export const getMovementSlip = (id) => {
  let route = `/wms/movements/${id}`;
  const config = {};
  config.method = 'GET';
  config.route = route;
  return queryService(config);
};
export const postMovement = (body) => {
  let route = `/wms/movement`;
  const config = {};
  config.method = 'POST';
  config.route = route;
  config.body = body;
  return queryService(config);
};
export const putMovement = (body, id) => {
  let route = `/wms/movement/${id}`;
  const config = {};
  config.method = 'PUT';
  config.route = route;
  config.body = body;
  return queryService(config);
};

// WAREHOUSE Teturn
export const getAllReturnStock = (
  page,
  limit,
  sortvalue = 'date_updated-desc',
  sku = '',
  return_number = '',
  return_date = '',
  return_by = '',
  received_by = '',
  location_name = '',
  status = '',
  date_created = '',
  created_by = ''
) => {
  let route = `/wms/returns?page=${page}&limit=${limit}&sort=${sortvalue}&sku=${sku}&return_number=${return_number}&returned_date=${return_date}&returned_by=${return_by}&received_by=${received_by}&location_name=${location_name}&status=${status}&date_created=${date_created}&created_by=${created_by}`;
  const config = {};
  config.method = 'GET';
  config.route = route;
  return queryService(config);
};
export const getReturnSlip = (id) => {
  let route = `/wms/returns/${id}`;
  const config = {};
  config.method = 'GET';
  config.route = route;
  return queryService(config);
};
export const postReturn = (body) => {
  let route = `/wms/return`;
  const config = {};
  config.method = 'POST';
  config.route = route;
  config.body = body;
  return queryService(config);
};
export const putReturn = (body, id) => {
  let route = `/wms/return/${id}`;
  const config = {};
  config.method = 'PUT';
  config.route = route;
  config.body = body;
  return queryService(config);
};

/** LOCATION  **/

export const getAllLocation = () => {
  const config = {};
  config.method = 'GET';
  config.route = `/wms/location?limit=99999`;
  return queryService(config);
};

export const getAllLocations = (
  filter_status = '',
  search = '',
  page = 0,
  limit = 10,
  sort = 'date_created-DESC'
) => {
  const config = {};
  config.method = 'GET';
  config.route = `/wms/location?filter_status=${filter_status}&search=${search}&page=${page}&limit=${limit}&sort=${sort}`;
  return queryService(config);
};

export const getLocationByID = (id) => {
  const config = {};
  config.method = 'GET';
  config.route = `/wms/location/${id}`;
  return queryService(config);
};

export const postLocation = (body) => {
  const config = {};
  config.method = 'POST';
  config.route = `/wms/location`;
  config.body = body;
  return queryService(config);
};

export const updateLocation = (body, id) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/wms/location/${id}`;
  config.body = body;
  return queryService(config);
};

export const updateLocationStatus = (body, id) => {
  const config = {};
  config.method = 'PUT';
  config.route = `/wms/location/status/${id}`;
  config.body = body;
  return queryService(config);
};

export const getWaitlist = (
  page = 1,
  limit = 10,
  sorted = '',
  start_date = '',
  end_date = '',
  brand_id = '',
  sku = '',
  category_id = ''
) => {
  const config = {};
  config.method = 'GET';
  config.route = `/waitlist?page=${page}&limit=${limit}&sort=${sorted}&start_date=${start_date}&end_date=${end_date}&product_brand_id=${brand_id}&sku=${sku}&category_id=${category_id}`;
  return queryService(config);
};
export const deleteWaitlist = (id) => {
  const config = {};
  config.method = 'DELETE';
  config.route = `/waitlist/${id}`;
  return queryService(config);
};
